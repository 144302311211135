
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Open+Sans:wght@400;700&family=Lato:wght@400;700&family=Montserrat:wght@400;700&family=Source+Sans+Pro:wght@400;700&family=Metrophobic&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Archivo:ital,wght@0,100..900;1,100..900&family=Exo+2:ital,wght@0,100..900;1,100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
:root {
  line-height: 1.5;
  font-weight: 400;
}

body {
  @apply font-sourceSansPro;
}
/* styles.css or any other CSS file you are using */
.gradient-text {
  background: linear-gradient(
    90deg,
    #58c4f2,
    #008dd2
  ); /* Blue gradient colors */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
/* Define the keyframes for the animated gradient */
@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Apply the gradient and animation to the text */
.animated-gradient-text {
  background: linear-gradient(
    90deg,
    #58c4f2,
    #008dd2,
    #58c4f2
  ); /* Blue gradient colors */
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  animation: gradientShift 3s ease-in-out infinite;
}

#service-card::-webkit-scrollbar{
  display: none;
}


.custom-scrollbar::-webkit-scrollbar {
  width: 0px;
}

.custom-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.mySwiper {
  overflow: visible;
}

.swiper-slide {
  opacity: 0.2;
  transform: scale(0.8);
  transition: transform 0.5s, opacity 0.5s;
}

.swiper-slide-active {
  opacity: 1;
  transform: scale(1.1);
}

.swiper-button-next,
.swiper-button-prev {
  color: #008dd2;
  padding: 1rem;
  border-radius: 100%;
  transition: background 0.3s;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: none;
  color: white;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  color:white;
  font-size: 1.5rem;
}
.rainbow-shadow {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0); /* default shadow */
}


.transition-shadow {
  transition: box-shadow 0.3s ease-in-out;
}
/* // #58c4f2
// #008dd2 */
